import React, { useContext } from "react";
import { GlobalContext } from "../../../context/global.context";
import TurntableImages from "./img";
import "./turntable.scss";
import clsx from "clsx";

export const Turntable = () => {
  const { playing } = useContext(GlobalContext).status;
  return (
    <div className={clsx("turntable", playing && "turntable--loading")}>
      <img
        alt="Phatbeatz turntable"
        src={
          playing
            ? TurntableImages.TurntableGif
            : TurntableImages.TurntableStart
        }
      />
    </div>
  );
};
