import "./home-footer.scss";
import PatternComponent from "../../common/pattern/pattern.component";

const HomeFooter = () => {
  return (
    <div className="home-footer">
      <span className="home-footer__text">
        breaks, beats, vibes and grooves 4 all y’all cats!
        <PatternComponent
          patternType="2"
          styles={{
            bottom: -20,
            left: "-10%",
          }}
        />
      </span>
    </div>
  );
};

export default HomeFooter;
