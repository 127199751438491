import ErrorImg from "./red_light.png";
import LoadingImg from "./yellow_light.png";
import OkImg from "./green_light.png";

const StatusImages = {
  ErrorImg,
  LoadingImg,
  OkImg,
};

export default StatusImages;
