import { useContext } from "react";
import { Link } from "react-scroll";
import { RadioButtons } from "../../home/radio-buttons/radio-buttons.component";
import { GlobalContext } from "../../../context/global.context";
import "./about-footer.scss";

import NavigationButtons from "../../common/navigation-buttons/navigation-buttons";

const AboutFooter = () => {
  const { howlerRef } = useContext(GlobalContext);

  return (
    <div className="about-footer">
      <div className="about-footer__left">
        <p>
          <strong>phatbeatz.online is a non-profit project driven by:</strong>
        </p>
        <p>Vibedigga aka Val Nice - Music Selection</p>
        <p>15thvm - Graphic design and Visual concepts</p>
        <p>Clipper - Website Development</p>
      </div>
      <div className="about-footer__right">
        <div className="about-footer__right__item about-footer__right__item--nav">
          <NavigationButtons />
        </div>
        <div className="about-footer__right__item">
          {howlerRef && <RadioButtons howlerRef={howlerRef} />}
        </div>
        <div className="about-footer__right__item about-footer__right__item--to-top">
          <Link
            to="home"
            activeClass="base-btn--active"
            smooth
            className="base-btn"
          >
            Back to top
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutFooter;
