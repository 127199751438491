import "./main-text.scss";
import { mainTextMap } from "../../../constants/main-text-map";
import { useContext } from "react";
import { GlobalContext } from "../../../context/global.context";

const MainText = () => {
  const { translations } = useContext(GlobalContext);

  return (
    translations && (
      <div className="about-main-text">
        {mainTextMap.map((textStyle, i) => (
          <p
            key={`text-paragraph-${i}`}
            className={`about-main-text--${textStyle}`}
          >
            {translations[`about.paragraph${i}`]}
          </p>
        ))}
      </div>
    )
  );
};

export default MainText;
