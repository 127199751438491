import React, { useEffect, useState } from "react";
import { reelMessages } from "../constants/reel-messages";
import axios from "axios";
import { TRANSLATIONS_EN } from "../constants/links";

export const initialGlobalContext = {
  isMobile: false,
  status: {
    loading: false,
    playing: false,
    mute: false,
    error: false,
    custom: false,
  },
  message: reelMessages.DEFAULT,
  setMessage: () => "",
  setCustom: () => "",
  setHowler: null,
  howlerRef: null,
  isLoading: false,
  setIsLoading: () => {},
};

export const GlobalContext = React.createContext(initialGlobalContext);

export const GlobalContextProvider = ({ children }) => {
  const [howler, setHowler] = useState(null);
  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [error, setError] = useState(false);
  const [mute, setMute] = useState(false);
  const [custom, setCustom] = useState(false);
  const [messages, setMessages] = useState(reelMessages);
  const [message, setMessage] = useState(reelMessages.DEFAULT);
  const [mobileView, setMobileView] = useState(false);
  const [translations, setTranslations] = useState(null);
  const globalContext = {
    ...initialGlobalContext,
    status: {
      playing,
      mute,
      loading,
      error,
    },
    setPlaying,
    setMute,
    setLoading,
    error,
    setError,
    howlerRef: howler,
    setHowler,
    message,
    setMessage,
    messages,
    setMessages,
    custom,
    setCustom,
    mobileView,
    setMobileView,
    translations,
    setTranslations,
  };

  const setIsMobileView = () => {
    setMobileView(window.innerWidth < 640);
  };

  useEffect(() => {
    axios.get("/json/statuses.json").then(({ data }) => {
      if (data) {
        setMessages(data);
      }
    });

    axios.get(TRANSLATIONS_EN).then(({ data }) => {
      if (data) {
        setTranslations(data);
        console.log(data);
      }
    });

    setIsMobileView();
    window.addEventListener("resize", setIsMobileView);

    return () => {
      window.removeEventListener("resize", setIsMobileView);
    };
  }, []);

  return (
    <GlobalContext.Provider value={globalContext}>
      {children}
    </GlobalContext.Provider>
  );
};
