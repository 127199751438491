import React from "react";
import "./App.scss";
import { Background } from "./components/background/background.component";
import { GlobalContextProvider } from "./context/global.context";
import Home from "./components/home/home";
import Howler from "./components/howler/howler.component";
import About from "./components/about/about.component";

const App = () => {
  return (
    <GlobalContextProvider>
      <Howler />
      <Background />
      <Home />
      <About />
    </GlobalContextProvider>
  );
};

export default App;
