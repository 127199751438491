import PlayBtnImg from "./button_play.png";
import StopBtnImg from "./button_stop.png";
import SoundBtnImg from "./button_sound.png";
import NoSoundBtnImg from "./button_no_sound.png";

const RadioButtonImages = {
  PlayBtnImg,
  StopBtnImg,
  SoundBtnImg,
  NoSoundBtnImg,
};

export default RadioButtonImages;
