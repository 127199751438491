import "./pattern.component.scss";
import Pattern1Img from "./img/pattern1.png";
import Pattern2Img from "./img/pattern2.png";

const PatternComponent = ({ patternType = 1, styles = {} }) => {
  const patternImg = {
    1: Pattern1Img,
    2: Pattern2Img,
  };

  return (
    <img
      alt=""
      className="pattern"
      src={patternImg[patternType]}
      style={styles}
    />
  );
};

export default PatternComponent;
