import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../context/global.context";
import ReactHowler from "react-howler";
import { RADIO_LINKS } from "../../constants/links";
import axios from "axios";

const Howler = () => {
  const { status, setHowler, setLoading, setError, setPlaying } =
    useContext(GlobalContext);
  const { playing } = status;
  const [radioLink, setRadioLink] = useState("");
  const howlerRef = useRef();

  const setErrorState = (err) => {
    setError(true);
    setLoading(false);
    setPlaying(false);
    console.error(err);
  };

  const loadRadio = (useAlternative) => {
    const link = useAlternative ? RADIO_LINKS.ALTERNATIVE : RADIO_LINKS.MAIN;

    axios
      .get(link)
      .then(() => {
        if (!radioLink) {
          setRadioLink(link);
        }
      })
      .catch((err) => {
        if (useAlternative) {
          setErrorState(err);
        } else {
          loadRadio(true);
        }
      });
  };

  useEffect(() => {
    if (howlerRef && howlerRef.current && setHowler) {
      setHowler(howlerRef.current);
    }
  }, [howlerRef, setHowler]);

  useEffect(() => {
    loadRadio();
  }, []);

  return (
    <ReactHowler
      src={`${radioLink}/listen.mp3`}
      preload={false}
      html5={true}
      // onLoad
      playing={playing}
      onLoadError={setErrorState}
      onPlayError={setErrorState}
      onPlay={() => {
        setPlaying(true);
      }}
      onStop={() => setPlaying(false)}
      // onPause={() => setIsPlaying(false)}
      ref={howlerRef}
    />
  );
};

export default Howler;
