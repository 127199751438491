import "./about.scss";
import MainText from "./main-text/main-text.component";
import Headphones from "./headphones/headphones.component";
import AboutFooter from "./about-footer/about-footer.component";
import { Element } from "react-scroll";

const About = () => {
  return (
    <Element name="about" className="about-holder">
      <MainText />
      <AboutFooter />
      <Headphones />
    </Element>
  );
};

export default About;
