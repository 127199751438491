import { navigationButtons } from "../../../constants/navigation-buttons";
import { Link } from "react-scroll";
import "./navigation-buttons.scss";

const NavigationButtons = () => {
  return (
    <div className="navigation-buttons">
      {navigationButtons.map((buttonText) => (
        <Link
          key={buttonText}
          activeClass="base-btn--active"
          className="base-btn"
          to={buttonText}
          smooth
        >
          {buttonText}
        </Link>
      ))}
    </div>
  );
};

export default NavigationButtons;
