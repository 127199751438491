import HeadphonesImages from "./img";
import { useContext } from "react";
import { GlobalContext } from "../../../context/global.context";
import "./headphones.scss";

const Headphones = () => {
  const { status } = useContext(GlobalContext);

  return (
    <img
      className="headphones"
      alt="phatbeatz headphones"
      src={
        status.playing
          ? HeadphonesImages.HeadphonesAnim
          : HeadphonesImages.HeadphonesStill
      }
    />
  );
};

export default Headphones;
