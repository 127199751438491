import React, { useContext, useEffect, useState } from "react";
import "./radio-buttons.scss";
import { GlobalContext } from "../../../context/global.context";
import { maxVol, Volume } from "../../../constants/volume";
import buttonImages from "./img/radio-buttons";
import VolumeImages from "./img/volume";

export const RadioButtons = ({ howlerRef }) => {
  const { setLoading, status, setError, setMute } = useContext(GlobalContext);
  const [volumeIndex, setVolumeIndex] = useState(maxVol);

  const changeVolume = () => {
    const vol = volumeIndex + 1 >= Volume.length ? 0 : volumeIndex + 1;
    setVolumeIndex(vol);
    howlerRef.volume(Volume[vol]);
  };

  const { loading, playing, mute } = status;

  const startStopRadio = (startPlaying) => {
    if (startPlaying) {
      setLoading(true);
      setError(false);
      howlerRef.play();
    } else {
      howlerRef.stop();
    }
  };

  useEffect(() => {
    if (playing) {
      setLoading(false);
    }
  }, [playing, setLoading]);

  return (
    <div className="radioButtons">
      <div
        onClick={() => startStopRadio(true)}
        className={`radioButtons__button 
					${playing || loading ? "radioButtons__button--disabled" : ""}`}
      >
        <img alt="play radio" src={buttonImages.PlayBtnImg} />
      </div>
      <div
        onClick={() => startStopRadio()}
        className={`radioButtons__button 
					${!playing ? "radioButtons__button--disabled" : ""}`}
      >
        <img alt="stop radio" src={buttonImages.StopBtnImg} />
      </div>
      <div
        className={`radioButtons__button`}
        onClick={() => {
          howlerRef.mute(!mute);
          setMute(!mute);
        }}
      >
        <img
          alt="mute_button"
          src={mute ? buttonImages.NoSoundBtnImg : buttonImages.SoundBtnImg}
        />
      </div>
      <div className={`radioButtons__button`} onClick={() => changeVolume()}>
        <img alt="current radio volume" src={VolumeImages[volumeIndex + 1]} />
      </div>
    </div>
  );
};
