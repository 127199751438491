import React, { useContext, useEffect } from "react";
import { Element } from "react-scroll";
import { Turntable } from "./turntable/turntable.component";
import axios from "axios";
import MessageReel from "./message-reel/message-reel";
import HeaderComponent from "./header/header.component";
import CrateImg from "./img/crate.png";
import HomeFooter from "./home-footer/home-footer";
import { GlobalContext } from "../../context/global.context";
import { isAfter, isBefore, isDate } from "date-fns";
import "./home.scss";

const Home = () => {
  const { setMessage, messages, setCustom, status } = useContext(GlobalContext);
  const { playing, loading, mute, error } = status;
  const setCustomMessage = () => {
    const { start, stop, message } = messages.CUSTOM_STATUS;
    const currentDate = new Date();
    const startDate = new Date(start * 1000);
    const stopDate = new Date(stop * 1000);

    if (
      isDate(startDate) &&
      isAfter(currentDate, startDate) &&
      (!isDate(stopDate) || isBefore(currentDate, stopDate))
    ) {
      setMessage(message);
      return true;
    }
    return false;
  };

  const setCurrentMessage = () => {
    if (setCustomMessage()) {
      setCustom(true);
    } else if (error) {
      setMessage(messages.ERROR);
    } else if (mute) {
      setMessage(messages.MUTE);
    } else if (loading) {
      setMessage(messages.LOADING);
    } else if (playing) {
      setMessage(messages.PLAYING);
    } else {
      setMessage(messages.DEFAULT);
    }
  };

  useEffect(() => {
    setCurrentMessage();
  }, [messages, status]);

  return (
    <Element className="home">
      <div className="home__item">
        <HeaderComponent />
      </div>
      <div className="home__item">
        <Turntable />
        <MessageReel />
      </div>
      <img
        alt="phatbeatz crate of vinyls"
        src={CrateImg}
        className="home__crate"
      />
      <HomeFooter />
    </Element>
  );
};

export default Home;
