import "./message-reel.scss";
import StatusImages from "./img";
import PatternComponent from "../../common/pattern/pattern.component";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/global.context";
import Ticker from "react-ticker";

const imgMap = {
  error: StatusImages.ErrorImg,
  loading: StatusImages.LoadingImg,
  playing: StatusImages.OkImg,
};

const MessageReel = () => {
  const { message, mobileView, status } = useContext(GlobalContext);
  const { custom, error, playing } = status;
  const [showTicker, setShowTicker] = useState(false);

  useEffect(() => {
    setShowTicker(false);
    if (message) {
      setTimeout(() => {
        setShowTicker(true);
      }, 1);
    }
  }, [message]);

  return (
    <div className="message-reel">
      <div className="message-reel__text">
        {showTicker && (
          <Ticker mode="await" speed={mobileView ? 8 : 13} offset="100%">
            {() => <span>{message}</span>}
          </Ticker>
        )}
      </div>
      <PatternComponent
        styles={{
          top: -80,
          left: -160,
        }}
      />
      <img
        alt="current status of the radio"
        src={
          error || custom
            ? imgMap.error
            : playing
            ? imgMap.playing
            : imgMap.loading
        }
        className="message-reel__status"
      />
    </div>
  );
};

export default MessageReel;
