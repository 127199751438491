import { RadioButtons } from "../radio-buttons/radio-buttons.component";
import { GlobalContext } from "../../../context/global.context";
import { useContext, useState } from "react";
import HeaderImages from "./img";
import NavigationButtons from "../../common/navigation-buttons/navigation-buttons";
import "./header.scss";

const HeaderComponent = () => {
  const { status, howlerRef } = useContext(GlobalContext);
  const [logoHover, setLogoHover] = useState(false);

  const { loading } = status;

  return (
    <header className={"header"}>
      <div
        className="header__logo"
        onMouseEnter={() => setLogoHover(true)}
        onMouseLeave={() => setLogoHover(false)}
      >
        <img
          alt="logo"
          // TODO: start/ stop gif to reduce load
          src={
            loading || logoHover
              ? HeaderImages.LogoAnimation
              : HeaderImages.Logo
          }
        />
      </div>
      <div className="header__radio">
        {howlerRef && <RadioButtons howlerRef={howlerRef} />}
      </div>
      <div className="header__navigation">
        <NavigationButtons />
      </div>
    </header>
  );
};

export default HeaderComponent;
